import {BrowserModule, Title} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {AppComponent} from './app.component';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AppRoutingModule} from './app.routing';
import {AppDeclarations} from './app.declarations';
import {AppProviders} from './app.providers';
import {CustomMaterialModule} from './material.module';
import {TranslateModule} from '@ngx-translate/core';
import {TimeagoModule} from 'ngx-timeago';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {ScrollingModule as ExperimentalScrollingModule} from '@angular/cdk-experimental/scrolling';
import {CdkTreeModule} from '@angular/cdk/tree';
import {httpInterceptorProviders} from './core/interceptors/interceptors';
import {NgxFileDropModule} from 'ngx-file-drop';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {LottieModule} from 'ngx-lottie';
import Lottie from 'lottie-web/build/player/lottie_light';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {CommonModulesModule} from '../common/modules/common-modules.module';
import {appI18NConfig} from '../common/app-i18-config';
import {SegmentModule} from 'ngx-segment-analytics';
import {CustomerService} from '../modules/customer/customer.service';
import {combineLatest} from 'rxjs';
import {BaseModule} from './base/base.module';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {en_US, NZ_I18N} from 'ng-zorro-antd/i18n';
import {IntercomService} from '../modules/intercom/intercom.service';
import {ErrorsModule} from '../modules/errors/errors.module';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {AuthModule} from '@auth0/auth0-angular';
import {NzDropDownModule} from 'ng-zorro-antd/dropdown';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import {NzSelectModule} from 'ng-zorro-antd/select';
import {FeatureFlagsModule} from '../../platform-common/feature-flags/feature-flags.module';
import {NzFormModule} from 'ng-zorro-antd/form';
import {NzInputModule} from 'ng-zorro-antd/input';
import {NzTypographyModule} from 'ng-zorro-antd/typography';
import {NzDividerModule} from 'ng-zorro-antd/divider';
import {AuthService} from './core/authentication/auth.service';
import {map, startWith} from 'rxjs/operators';
import {EffectivePermissionsModule} from './core/effective-permissions/effective-permissions.module';
import {WindowSessionContext} from 'src/modules/window-session-context';
import {AnalyticsModule} from 'src/modules/analytics/analytics.module';
import {PermissionsModule} from './core/permissions/permissions.module';
import {PlatformAnalyticsService} from '../modules/analytics/local-services/platform-analytics.service';
import {CustomerModule} from '../modules/customer/customer.module';
import {HostContextConsumerService} from '../host-context';
import {ModelsManagementModule} from '../modules/models-management/models-management.module';
import {GtmModule} from '../modules/gtm/gtm.module';
import {LoginAnalyticsService} from '../modules/analytics/local-services/login-analytics.service';
import {BannerModule} from '../modules/banner/banner.module';

export function playerFactory() {
    return Lottie;
}

@NgModule({
    declarations: [AppDeclarations, AppComponent],
    imports: [
        TranslateModule.forRoot(appI18NConfig),
        BaseModule,
        CommonModulesModule,
        BrowserModule,
        FormsModule,
        ScrollingModule,
        ExperimentalScrollingModule,
        CdkTreeModule,
        TimeagoModule.forRoot(),
        BsDropdownModule.forRoot(),
        PopoverModule.forRoot(),
        TooltipModule.forRoot(),
        CustomMaterialModule,
        AppRoutingModule,
        ReactiveFormsModule,
        HttpClientModule,
        LottieModule.forRoot({player: playerFactory}),
        NgxFileDropModule,
        SegmentModule.forRoot({loadOnInitialization: false}),
        StoreModule.forRoot({}),
        EffectsModule.forRoot(),
        StoreDevtoolsModule.instrument({maxAge: 25}),
        NzButtonModule,
        ErrorsModule,
        NzIconModule,
        AuthModule.forRoot(),
        NzDropDownModule,
        NzToolTipModule,
        NzSelectModule,
        FeatureFlagsModule.forRoot({
            depsMap: {
                customerService: CustomerService,
                authService: AuthService,
                hcc: HostContextConsumerService,
            },
            factory: ({customerService, authService, hcc}) => ({
                context$: combineLatest([
                    authService.user$,
                    customerService.selectedTenant$,
                    customerService.selectedTenantDetails$,
                ]).pipe(
                    map(([user, tenant, tenantDetails]) => {
                        if (!user || !tenant || !tenantDetails) {
                            return;
                        }
                        return {
                            tenant: {
                                id: tenant,
                                accountType: tenantDetails.account_type,
                            },
                            user: {
                                email: user.email,
                            },
                        };
                    }),
                    startWith({
                        tenant: {id: undefined, accountType: undefined},
                        user: {email: undefined},
                    }),
                ),
                config: {
                    unleash: {
                        url: `https://${hcc.config.getConfig().unleashProxyHost}/proxy`,
                        clientKey: hcc.config.getConfig().unleashClientKey,
                        appName: 'Platform',
                    },
                },
            }),
        }),
        NzFormModule,
        NzInputModule,
        NzTypographyModule,
        NzDividerModule,
        EffectivePermissionsModule,
        AnalyticsModule,
        PermissionsModule,
        CustomerModule,
        ModelsManagementModule,
        GtmModule,
        BannerModule,
    ],
    providers: [
        httpInterceptorProviders,
        AppProviders,
        {provide: NZ_I18N, useValue: en_US},
        Title,
        PlatformAnalyticsService,
        LoginAnalyticsService,
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule {
    constructor(_intercomService: IntercomService, _WindowSessionContext: WindowSessionContext) {}
}
