import {Location} from '@angular/common';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {NavigationEnd, Router} from '@angular/router';
import {AuthService} from '../../authentication/auth.service';

@Component({
    selector: 'app-top-navigation-bar',
    templateUrl: './top-navigation-bar.component.html',
    styleUrls: ['./top-navigation-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopNavigationBarComponent implements OnInit {
    public hideTopBar = true;
    public user$ = this.authService.user$;
    public userCompanyDisplayName$ = this.authService.defaultTenantDisplayName$;

    constructor(
        private authService: AuthService,
        private router: Router,
        private location: Location,
        private dialog: MatDialog,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        this.hideTopBar = this.location.path().includes('/login');
        this.cdr.detectChanges();
        this.router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                const {url} = val;
                this.hideTopBar = this.isLoginPageState(url);
                this.cdr.detectChanges();
            }
        });
    }

    logout() {
        this.dialog.closeAll();
        this.authService.logout();
    }

    private isLoginPageState(url: string): boolean {
        return url.includes('login');
    }
}
